<template>
  <div>
    <div class="ex-300-content">
      <main class="main text-left d-block d-lg-flex">
        <div class="read-more">
          <a @click="$router.go(-1)">
            <i class="fas fa-arrow-left fa-2x"></i>
          </a>
        </div>
        <div class="one-of-two p-4 p-lg-5">
          <h2 class="mb-3 font-weight-bold">{{locale[lang].EXERCICES_APP.FIRST.TITLE}}</h2>
          <h5 class="mb-3">{{locale[lang].EXERCICES_APP.FIRST.SUB}}</h5>
          <div class="d-flex align-items-center mb-3">
            <div class="icon-c mr-2 d-flex align-items-center justify-content-center">
              <img class="icon" src="@/assets/Images/ExercicesApp/health-app.png" alt="icon" />
            </div>
            <p class="ml-4" v-html="locale[lang].EXERCICES_APP.FIRST.CONTENT_1">
            </p>
          </div>
          <div class="d-flex align-items-center mb-3">
            <div class="icon-c mr-2 d-flex align-items-center justify-content-center">
              <img class="icon" src="@/assets/Images/ExercicesApp/medical-report.png" alt="icon" />
            </div>
            <p class="ml-4" v-html="locale[lang].EXERCICES_APP.FIRST.CONTENT_2">
            </p>
          </div>
          <div class="d-flex align-items-center mb-3">
            <div class="icon-c mr-2 d-flex align-items-center justify-content-center">
              <img class="icon" src="@/assets/Images/ExercicesApp/goal.png" alt="icon" />
            </div>
            <div class="ml-4">
              <p>{{locale[lang].EXERCICES_APP.FIRST.CONTENT_3.TEXT}}</p>
              <ul class="row">
                <li class="col-12 col-lg-4 pl-0" v-for="(text, key) in locale[lang].EXERCICES_APP.FIRST.CONTENT_3.LIST" :key="key">{{ text }}</li>
              </ul>
            </div>
          </div>
          <h5>
            {{locale[lang].EXERCICES_APP.FIRST.END}}
          </h5>
        </div>
        <div class="one-of-two p-4 p-lg-5 d-flex align-items-center justify-content-center bg-i">
          <img class="img-fluid" src="@/assets/Images/Home/gif-g (6).gif" alt="Child" />
        </div>
        <div class="one-of-two p-4 p-lg-5 first">
          <h1 class="font-weight-bold">{{ locale[lang].EXERCICES_APP.CATEGORIES[0] }}</h1>
          <img class="mt-5 img-fluid" src="@/assets/Images/Cycles/PLeft-1.png" alt="exercice" />
        </div>
        <div class="one-of-two p-4 p-lg-5 d-flex align-items-center justify-content-center">
          <img src="@/assets/Images/ExercicesApp/1.png" alt="Child" class="mt-5 img-fluid" />
        </div>
        <div class="one-of-two p-4 p-lg-5 first">
          <h1 class="font-weight-bold">{{ locale[lang].EXERCICES_APP.CATEGORIES[1] }}</h1>
          <img class="mt-5 img-fluid" src="@/assets/Images/Cycles/PERIODISAT44 (2).png" alt="exercice" />
        </div>
        <div class="one-of-two p-4 p-lg-5 d-flex align-items-center justify-content-center">
          <img src="@/assets/Images/ExercicesApp/2.png" alt="Child" class="mt-5 img-fluid" />
        </div>
        <div class="one-of-two p-4 p-lg-5 first">
          <h1 class="font-weight-bold">{{ locale[lang].EXERCICES_APP.CATEGORIES[2] }}</h1>
          <img src="@/assets/Images/ExercicesApp/Vitesse.png" alt="Child" class="mt-5 img-fluid" />
        </div>
        <div class="one-of-two p-4 p-lg-5 d-flex align-items-center justify-content-center">
          <img src="@/assets/Images/ExercicesApp/3.png" alt="Child" class="mt-5 img-fluid" />
        </div>
        <div class="one-of-two p-4 p-lg-5 first">
          <h1 class="font-weight-bold">{{ locale[lang].EXERCICES_APP.CATEGORIES[3] }}</h1>
          <img src="@/assets/Images/ExercicesApp/Force.png" alt="Child" class="mt-5 img-fluid" />
        </div>
        <div class="one-of-two p-4 p-lg-5 d-flex align-items-center justify-content-center">
          <img src="@/assets/Images/ExercicesApp/4.png" alt="Child" class="mt-5 img-fluid" />
        </div>
        <div class="one-of-two p-4 p-lg-5 first">
          <h1 class="font-weight-bold">{{ locale[lang].EXERCICES_APP.CATEGORIES[4] }}</h1>
          <img src="@/assets/Images/ExercicesApp/Coordination.png" alt="Child" class="mt-5 img-fluid" />
        </div>
        <div class="one-of-two p-4 p-lg-5 d-flex align-items-center justify-content-center">
          <img src="@/assets/Images/ExercicesApp/5.png" alt="Child" class="mt-5 img-fluid" />
        </div>
        <div class="one-of-two p-4 p-lg-5 first">
          <h1 class="font-weight-bold">{{ locale[lang].EXERCICES_APP.CATEGORIES[5] }}</h1>
          <img src="@/assets/Images/ExercicesApp/Jeunes.png" alt="Child" class="mt-5 img-fluid" />
        </div>
        <div class="one-of-two p-4 p-lg-5 d-flex align-items-center justify-content-center">
          <img src="@/assets/Images/ExercicesApp/6.png" alt="Child" class="mt-5 img-fluid" />
        </div>
        <div class="one-of-two p-4 p-lg-5 first">
          <h2 class="mb-3 font-weight-bold">{{locale[lang].EXERCICES_APP.LAST.TITLE}}</h2>
          <div class="row mb-3">
            <div class="col-12 col-lg-6">
              <div class="d-flex mb-4 align-items-center">
                <div class="icon-c mr-3 d-flex align-items-center justify-content-center">
                  <img class="icon" src="@/assets/Images/ExercicesApp/like.png" alt="icon" />
                </div>
                <p class="mb-0">
                  {{locale[lang].EXERCICES_APP.LAST.ITEMS[0]}}
                </p>
              </div>
              <div class="d-flex mb-4 align-items-center">
                <div class="icon-c mr-3 d-flex align-items-center justify-content-center">
                  <img class="icon" src="@/assets/Images/ExercicesApp/apps.png" alt="icon" />
                </div>
                <p class="mb-0">{{locale[lang].EXERCICES_APP.LAST.ITEMS[1]}}</p>
              </div>
              <div class="d-flex mb-4 align-items-center">
                <div class="icon-c mr-3 d-flex align-items-center justify-content-center">
                  <img class="icon" src="@/assets/Images/ExercicesApp/soccer.png" alt="icon" />
                </div>
                <p class="mb-0">{{locale[lang].EXERCICES_APP.LAST.ITEMS[2]}}</p>
              </div>
              <div class="d-flex mb-4 align-items-center">
                <div class="icon-c mr-3 d-flex align-items-center justify-content-center">
                  <img class="icon" src="@/assets/Images/ExercicesApp/phone-call.png" alt="icon" />
                </div>
                <p class="mb-0">{{locale[lang].EXERCICES_APP.LAST.ITEMS[3]}}</p>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="d-flex mb-4 align-items-center">
                <div class="icon-c mr-3 d-flex align-items-center justify-content-center">
                  <img class="icon" src="@/assets/Images/ExercicesApp/jumping-rope.png" alt="icon" />
                </div>
                <p class="mb-0">{{locale[lang].EXERCICES_APP.LAST.ITEMS[4]}}</p>
              </div>
              <div class="d-flex mb-4 align-items-center">
                <div class="icon-c mr-3 d-flex align-items-center justify-content-center">
                  <img class="icon" src="@/assets/Images/ExercicesApp/app.png" alt="icon" />
                </div>
                <p class="mb-0">
                  {{locale[lang].EXERCICES_APP.LAST.ITEMS[5]}}
                </p>
              </div>
              <div class="d-flex mb-4 align-items-center">
                <div class="icon-c mr-3 d-flex align-items-center justify-content-center">
                  <img class="icon" src="@/assets/Images/ExercicesApp/smartphone.png" alt="icon" />
                </div>
                <p class="mb-0">
                  {{locale[lang].EXERCICES_APP.LAST.ITEMS[6]}}
                </p>
              </div>

            </div>
          </div>
          <div class="row links mt-2">
            <div class="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
              <a href="javascript:;" @click="goTo">
                <img class="img-fluid mb-2" src="@/assets/Images/Home/App store.png" alt="app-store" width="200"/>
              </a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-12 text-center">
              <a href="https://play.google.com/store/apps/details?id=com.adeveloper.soccer10" target="_blank">
                <img class="img-fluid mb-2" src="@/assets/Images/Home/google play.png" alt="google-play" width="200"/>
              </a>
            </div>
          </div>
        </div>
        <div class="one-of-two p-4 p-lg-5 mb-4 d-flex align-items-center justify-content-center bg-i">
          <img class="img-fluid" src="@/assets/Images/Home/gif-g (6).gif" alt="Child" />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
  import NavBar from "@/components/essential/NavBar.vue";
  import Footer from "@/components/essential/Footer.vue";
  import OopsImage from "@/assets/Images/oops.jpeg";
  export default {
    components: {
      NavBar,
      Footer,
    },
    data() {
      return {
        OopsImage,
      };
    },
    mounted() {
      // Wrapper
      const wrapper = document.querySelector(".main");
      // Children
      const children = document.querySelectorAll(".one-of-two");

      // OnLoad listener
      setWrapperHeight();
      window.onscroll = handler;
      window.onresize = handler;

      function handler() {
        setWrapperHeight();
        const windowOffset = window.pageYOffset;
        children.forEach(function (child) {
          child.style.top = windowOffset + "px";
        });
        wrapper.style.left = "-" + windowOffset + "px";
      }

      // Calculates and set wrapper height
      function setWrapperHeight() {
        const wrapperWidth = wrapper.offsetWidth;
        wrapper.style.height = wrapperWidth - (window.innerWidth - window.innerHeight) + "px";
      }
    },
    methods: {
      goTo() {
        alert('bientôt disponible')
      },
    },
  };
</script>

<style scoped>
  #nav-container {
    background-color: #0f1010;
  }

  .bg-i {
    background-image:
      url("../assets/Images/Background/Trazado 1903.png"),
      url("../assets/Images/Background/Grupo 685.png");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 106% 82%, 25% 10%;
    padding-bottom: 10%;
  }

  .read-more {
    z-index: 9999;
    position: fixed;
    top: 36px;
    right: 48px;
  }

  .read-more a {
    color: #ff0313;
    cursor: pointer;
  }

  .icon-c {
    border-radius: 50%;
    background-color: #292929;
    height: 72px;
    min-width: 72px;
    width: 72px;
  }

  .icon {
    height: auto;
    filter: invert(1);
    width: 36px;
  }

  .first .img-fluid {
    max-height: 450px;
  }

  .img-fluid {
    border-radius: 16px;
  }

  .links .img-fluid {
    border-radius: 0;
  }

  main.main {
    background: url("../assets/Images/Coaches/Enmascarar grupo 129.svg") no-repeat;
    background-position: bottom;
    background-size: cover;
    background-attachment: fixed;
    color: white;
    display: flex;
    height: 100vh;
    position: relative;
    width: fit-content;
    -webkit-overflow-scrolling: touch;
  }

  main.main>.one-of-two {
    height: 100vh !important;
    min-width: 50vw;
    position: relative;
  }

  .go-to-home {
    color: white;
    text-decoration: underline;
  }

  @media screen and (max-width: 991px) {
    main.main>.one-of-two {
      background-color: #1b1b1b;
      height: fit-content!important;
      width: 100vw;
    }

    .read-more {
      top: 24px;
      right: 24px;
    }

    .bg-i {
      background-color: #1b1b1b;
      color: #ffffff!important;
    }

    h2 {
      font-size: 1.5rem;
    }

    h5 {
      font-size: 1rem;
    }
  }
</style>